<template>
	<section id="dashboard-ecommerce"><br>
    <b-row>
      <b-col>
        <home-statistics :data="statBUXIDA" />
      </b-col>
    </b-row>
    <b-row >
    	<b-col
			cols="6"
		>
        <factures />
      </b-col>
    	<b-col
			cols="6"
		>
        <contrats />
      </b-col>
    </b-row>
   <b-row >
    	<b-col
			cols="6"
		>
        <resa />
      </b-col>
    	<b-col
			cols="6"
		>
        <signatures />
      </b-col>
    </b-row>
    <b-row >
      <b-col>
        <resalst />
      </b-col>
    </b-row>
    <b-row >
      <b-col>
        <connexion />
      </b-col>
    </b-row>
	</section>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import HomeStatistics from './HomeStatistics.vue'
import connexion from './connexion.vue'
import factures from './factures.vue'
import contrats from './contrats.vue'
import resa from './resa.vue'
import signatures from './signatures.vue'
import resalst from './resalst.vue'
	
export default {
	components: {
		BRow,
		BCol,
    	HomeStatistics,
		connexion,
		factures,
		contrats,
		resa,
		signatures,
		resalst,
	},
	directives: {
		Ripple,
	},
	data() {
		return {
			statBUXIDA: [],
		}
	},
	created() {
		this.$http.get('/admin/statdata')
		.then(response => {
			if (response.status === 200) {
				this.statBUXIDA = response.data.statBUXIDA
			}
		})
	},	  
	methods: {
		voirplan: function (ui) {   
		  window.open(`https://ubicx.blob.core.windows.net/bbc/plans/${ui}.svgz`, '_blank')
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
