<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Connexions</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        300 dernières connexions à BUXIDA
      </b-card-text>
    </b-card-header>
    <b-card-body >
		<vue-good-table
			:columns="columnslog"
			:rows="rowslog"
			:pagination-options="{
			enabled: true,
			mode: 'records',
			perPage: 5,
			position: 'top',
			perPageDropdown: [5, 10, 20],
			dropdownAllowAll: false,
			jumpFirstOrLast : true,
			firstLabel : 'Première Page',
			lastLabel : 'Dernière Page',
			nextLabel: 'Suivant',
			prevLabel: 'Précédent',
			rowsPerPageLabel: 'ligne par page',
			ofLabel: 'de',
			pageLabel: 'page', // for 'pages' mode
			allLabel: 'Tout',
			infoFn: (params) => `my own page ${params.firstRecordOnPage}`, 
			}"
		>
		  <template
			slot="table-row"
			slot-scope="props"
		  >
			<span
				v-if="props.column.field === 'C_type' && props.row.C_type != 'Connexion réussie' && props.row.C_type != 'Déconnexion réussie'"
				style="font-weight: bold; color: blue;"
			>
					{{ props.row.C_type }}
			</span>
			<span v-else>
			  {{ props.formattedRow[props.column.field] }}
			</span>
		  </template>
		</vue-good-table>
	</b-card-body>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
	
export default {
	components: {
		BCard,
		BCardHeader,
		BCardTitle,
		BCardText,
		BCardBody,
		VueGoodTable,
	},
	data() {
		return {
			columnslog: [
				{
					label: 'Date',
					field: 'C_date',
				},
				{
					label: 'IP',
					field: 'C_ip',
				},
				{
				  label: 'Pays',
				  field: 'C_country',
				},
				{
				  label: 'Etat',
				  field: 'C_type',
				},
				{
				  label: 'Email',
				  field: 'C_email',
				},
				{
				  label: 'Société',
				  field: 'C_rs',
				},
				{
				  label: 'Utilisateur',
				  field: 'C_utilisateur',
				},
			],
			rowslog: [],
		}
	},
	created() {
		this.$http.get('/admin/lstlog')
		.then(response => { this.rowslog = response.data.Resultlog })
	},	
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
