var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"card-statistics",attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v("Connexions")]),_c('b-card-text',{staticClass:"font-small-2 mr-25 mb-0"},[_vm._v(" 300 dernières connexions à BUXIDA ")])],1),_c('b-card-body',[_c('vue-good-table',{attrs:{"columns":_vm.columnslog,"rows":_vm.rowslog,"pagination-options":{
			enabled: true,
			mode: 'records',
			perPage: 5,
			position: 'top',
			perPageDropdown: [5, 10, 20],
			dropdownAllowAll: false,
			jumpFirstOrLast : true,
			firstLabel : 'Première Page',
			lastLabel : 'Dernière Page',
			nextLabel: 'Suivant',
			prevLabel: 'Précédent',
			rowsPerPageLabel: 'ligne par page',
			ofLabel: 'de',
			pageLabel: 'page', // for 'pages' mode
			allLabel: 'Tout',
			infoFn: function (params) { return ("my own page " + (params.firstRecordOnPage)); }, 
			}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'C_type' && props.row.C_type != 'Connexion réussie' && props.row.C_type != 'Déconnexion réussie')?_c('span',{staticStyle:{"font-weight":"bold","color":"blue"}},[_vm._v(" "+_vm._s(props.row.C_type)+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }